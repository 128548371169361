<template>
  <div id="order-view-actions-spec-payment-error">
    <OrderViewActionAccept v-if="!isQuoteOwner" btn-icon="CreditCardIcon" :btn-text="$tc('payment.title', 1)" />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionsSpecPaymentError',

  components: {
    OrderViewActionAccept: () => import('@/views/order/OrderViewActionAccept.vue'),
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
  },
}
</script>
